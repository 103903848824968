import { Dayjs } from "dayjs";
import { RangeValue } from "rc-picker/lib/interface";
import { ISeller } from "./common";
import { IOrderItem, ItemType } from "./order";
import { ISite } from "./warehouse";

export interface ReturnRequestFilter {
  statuses?: string[];
  platformId?: number;
  sellerId?: number;
  requestDate?: RangeValue<Dayjs>;
  page?: number;
  pageSize?: number;
  orderIds?: string[];
  createdByEmail?: string;
}

export interface ReturnRequestGetListPayload extends Omit<ReturnRequestFilter, "requestDate" | "page" | "pageSize"> {
  fromDate?: string;
  toDate?: string;
  offset?: number;
  limit?: number;
}

export interface ReturnRequest {
  id: number;
  orderId: string;
  orderCode: string;
  items: ReturnedItem[];
  estimatedRefund: number;
  extraInfo: string;
  status: ReturnRequestStatus;
  reason: string;
  reasonId: string;
  createdByEmail: string;
  createdAt: string;
  comment: string;
  approvedByEmail: string;
  customerInstruction: string;
  refunds: ReturnRequestRefund[];
  returnFee?: number;
  siteCode?: string;
  siteId?: number;
  services?: ReturnedServiceItem[];
}

export interface ReturnedProduct
  extends Pick<ReturnedItem, "id" | "returnedQuantity" | "sellerSku" | "name" | "unitAfterTaxPrice"> {
  siteCode: ReturnRequest["siteCode"];
}

export interface ReturnedServiceItem {
  serviceId: number;
  sellerId: number;
  quantity: number; // requested quantity
  returnedQuantity: number; // approved quantity
  name: string;
  unitPrice: number;
  unitAfterTaxPrice: number;
  status: ReturnRequestServiceStatus;
}

export interface SharedReturnedItem {
  type: ItemType;
  identifier: string;
  name: string;
  sellerId: ISeller["id"];
  unitPrice: number;
  quantity: number;
  returnedQuantity?: number; // only exists after return request is created
  status?: ReturnRequestStatus | ReturnRequestServiceStatus; // only exists after return request is created
  // attributes for item
  sku?: string;
  sellerSku?: string;
  shipmentId?: ReturnedItem["shipmentId"];
  siteId?: ISite["id"];
  id?: number; // only exists after return request is created
  // attributes for service
  serviceId?: number;
}

export interface ReturnedItem {
  id: number;
  unitPrice: number;
  unitAfterTaxPrice: number;
  sellerSku: string;
  sku: string;
  name: string;
  quantity: number;
  returnedQuantity: number;
  status: ReturnRequestStatus; //use the same status with Return Request
  sellerId: ISeller["id"];
  shipmentId?: string;
}

export interface ReturnRequestRefund {
  paymentMethod: string;
}

export enum ReturnRequestStatus {
  cancelled = "RETURN_STATUS_CANCELLED",
  waitImportReturnItems = "RETURN_STATUS_WAITING_FOR_IMPORT_RETURN_ITEMS",
  waitApproval = "RETURN_STATUS_WAITING_APPROVAL",
  waitApprovalImported = "RETURN_STATUS_WAITING_APPROVAL_IMPORTED",
  waitApprovalCancelled = "RETURN_STATUS_WAITING_APPROVAL_CANCELLED",
  partialCancelled = "RETURN_STATUS_PARTIAL_CANCELLED",
  returned = "RETURN_STATUS_RETURNED",
  denied = "RETURN_STATUS_DENIED",
  approved = "RETURN_STATUS_APPROVED",
  rejected = "RETURN_STATUS_REJECTED",
  imported = "RETURN_STATUS_IMPORTED",
  closed = "RETURN_STATUS_CLOSED",
}

export enum ReturnRequestServiceStatus {
  approved = "RETURN_SERVICE_STATUS_APPROVED",
  cancelled = "RETURN_SERVICE_STATUS_CANCELLED",
  closed = "RETURN_SERVICE_STATUS_CLOSED",
  denied = "RETURN_SERVICE_STATUS_DENIED",
  waitApproval = "RETURN_SERVICE_STATUS_WAITING_APPROVAL",
}

export interface ReturnRequestCreateItemsPayload extends Pick<IOrderItem, "sku" | "quantity" | "sellerId"> {}

export interface ReturnRequestUpdateItemsPayload
  extends Pick<ReturnedItem, "id" | "name" | "shipmentId" | "sku" | "quantity" | "sellerId"> {}

export interface ReturnRequestUpsertServicesPayload
  extends Pick<ReturnedServiceItem, "serviceId" | "sellerId" | "quantity"> {}

export type ReturnRequestApproveForm = Pick<ReturnRequest, "estimatedRefund" | "customerInstruction">;

export interface ReturnRequestUpdateStatusPayload extends Pick<ReturnRequest, "id" | "status"> {
  estimatedRefund?: ReturnRequest["estimatedRefund"];
  customerInstruction?: ReturnRequest["customerInstruction"];
}

export interface ReturnRequestUpdatePayload
  extends Partial<
    Pick<
      ReturnRequest,
      "id" | "status" | "estimatedRefund" | "customerInstruction" | "reason" | "reasonId" | "comment" | "siteId"
    >
  > {
  customerNotify?: boolean;
  items?: ReturnRequestUpdateItemsPayload[];
  services?: ReturnRequestUpsertServicesPayload[];
}

export interface ReturnRequestCreatePayload
  extends Pick<
    ReturnRequest,
    "orderId" | "reason" | "reasonId" | "comment" | "estimatedRefund" | "customerInstruction" | "siteId"
  > {
  platformId: string;
  customerNotify: boolean;
  items: ReturnRequestCreateItemsPayload[];
  services: ReturnRequestUpsertServicesPayload[];
}

export interface ReturnData {
  returnRequests: ReturnRequest[];
  processingRequests: ReturnRequest[];
  finishedRequests: ReturnRequest[];
  returnedProducts: ReturnedProduct[];
  returnedServices: ReturnedServiceItem[];
}

export enum ReturnRequestApprovalType {
  auto = "auto",
  noNeedApproval = "no_need_approval",
}

export enum ReturnRequestBtnDefaultQuantity {
  MAX = "MAX",
  ZERO = "ZERO",
}

export interface CreateExportRequestForReturnRequest {
  orderIds?: string[];
  createdByEmail?: string;
  fromDate: number;
  toDate: number;
  statuses?: string[];
  platformId?: number;
  sellerId?: number;
}
