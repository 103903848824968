import { browserHistory, iframeUtils } from "utils";
import StaffIdentity from "@staff-admin/identity-sdk";
import { appConfig, workloadConfig } from "app-config";

const { isUseStaffAdminIDSDK } = appConfig;
const { allowUseSAISDKDomains = [] } = workloadConfig || {};

const { parentOrigin } = iframeUtils.getDataFromIframe();
const isAllowUseSAISDK = isUseStaffAdminIDSDK || allowUseSAISDKDomains?.includes(String(parentOrigin));
const staffIdentity = StaffIdentity(isAllowUseSAISDK);

const isLoggedIn = () => {
  return staffIdentity.isLoggedIn();
};

const login = () => {
  staffIdentity.login();
};

const logout = () => {
  // Tracking - Reset userId when logged out
  track("resetUserId");

  staffIdentity.logout(window.location.origin);
};

const getAccessToken = () => {
  return staffIdentity.getAccessToken();
};

const getUserInfo = () => {
  return staffIdentity.getUserInfo();
};

const getFullUserInfo = async () => {
  const fullUserInfo = await staffIdentity.getFullUserInfo();
  return fullUserInfo;
};

const denyAccess = () => {
  browserHistory.push("/403");
};

export default {
  isLoggedIn,
  login,
  logout,
  getAccessToken,
  getUserInfo,
  getFullUserInfo,
  denyAccess,
};
