import { FormInstance } from "antd/lib/form";
import { Dayjs } from "dayjs";
import {
  ICreatePurchaseInvoiceLine,
  IDeclarePurchaseInvoice,
  IDeclarePurchaseInvoiceGetParams,
  IPurchaseInvoiceCreatePayload,
  IPurchaseInvoiceInfo,
  ISeller,
  ISupplier,
} from "models";
import { IPurchaseOrderReturnInvoice, IPurchaseOrderReturnInvoiceCreateForm } from "models/purchase-order-return";
import { OptionData } from "rc-select/lib/interface";
import React, { createContext } from "react";

export interface IPurchaseInvoiceLineForm extends ICreatePurchaseInvoiceLine {
  price: number;
  quantityOnInvoice?: number;
  keyLine?: string;
}

export interface IPurchaseInvoiceCreateForm {
  poIds: string[];
  invoices: (IPurchaseInvoice & { invoiceId: IPurchaseInvoiceInfo["invoiceId"] })[];
  supplierId: ISupplier["id"];
}

export interface IPurchaseInvoice
  extends Omit<IPurchaseInvoiceCreatePayload, "dueDate" | "invoiceDate" | "invoiceLine"> {
  invoiceLine: IPurchaseInvoiceLineForm[];
  maxPaymentTerm: number;
  dueDate?: Dayjs;
  invoiceDate?: Dayjs;
  countIndex?: number;
}

interface IPurchaseInvoiceContext {
  purchaseInvoice: IDeclarePurchaseInvoice;
  enabledEpicOmni1407: boolean;
  supplierInfo: Pick<ISupplier, "taxCode" | "fullAddress">;
  purchaseInvoiceTableLoading: boolean;
  isConfirmMode: boolean;
  isChangedSupplier?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPurchaseInvoice: React.Dispatch<React.SetStateAction<IDeclarePurchaseInvoice>>;
  getDeclarePurchaseInvoiceData: (
    purchaseOrderIds: IDeclarePurchaseInvoiceGetParams["purchaseOrderIds"],
    sellerId: ISeller["id"]
  ) => void;
  form?: FormInstance<IPurchaseInvoiceCreateForm>;
  onChangeSupplier: React.Dispatch<OptionData>;
}

export const PurchaseInvoiceContext = createContext<IPurchaseInvoiceContext>({
  purchaseInvoice: {},
  enabledEpicOmni1407: false,
  supplierInfo: { taxCode: "", fullAddress: "" },
  purchaseInvoiceTableLoading: false,
  isConfirmMode: false,
  setLoading: () => null,
  setPurchaseInvoice: () => null,
  getDeclarePurchaseInvoiceData: () => null,
  onChangeSupplier: () => null,
});

interface IPurchaseOrderReturnInvoiceContext {
  purchaseOrderReturnInvoice: IPurchaseOrderReturnInvoice;
  form: FormInstance<IPurchaseOrderReturnInvoiceCreateForm>;
  loadingGetPORInvoice: boolean;
  loadingCreatePORInvoice: boolean;
  canCreatePORInvoice?: boolean;
  getPurchaseOrderReturnInvoice: () => void;
}

export const PurchaseOrderReturnInvoiceContext = createContext<IPurchaseOrderReturnInvoiceContext>({
  purchaseOrderReturnInvoice: {},
  form: {} as FormInstance<IPurchaseOrderReturnInvoiceCreateForm>,
  loadingGetPORInvoice: false,
  loadingCreatePORInvoice: false,
  getPurchaseOrderReturnInvoice: () => null,
});
