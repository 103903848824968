import dayjs, { Dayjs } from "dayjs";
import { DeliveryInfo } from "models";
import { RangeValue } from "rc-picker/lib/interface";
import { IPlatform, ISeller } from "./common";
import { MarketOrder } from "./marketOrders";
import { ISite, IWarehouse } from "./warehouse";

export interface Terminal {
  sellerID?: number;
  code: string;
  name: string;
}

export interface IOrderStatusOption {
  status: string;
  name: string;
  isActive: boolean;
}

export interface IShipment {
  id: string;
  shipmentSetCode: string;
  fulfillmentChannelName: string;
  sellerId: ISeller["id"];
  platformId: IPlatform["id"];
  parentId: string;
  status: ShipmentStatus;
  displayStatusName: string;
  displayStatusColor: string;
  allowPartialReturn?: boolean;
  grandTotal: number;
  isAuto: boolean | null;
  items: IOrderItem[];
  routerItems: IOrderItem[];
  delivery?: DeliveryInfo;
  codAmount?: number;
  shippingInfo?: IShippingInfo | null;
  allowedUserActions?: AllowedUserAction[];
  fulfillmentProviderName?: string;
}

export interface RawOrderItem {
  lineItemId: string;
  sku: string;
  sellerSku: string;
  name: string;
  quantity: number;
  sellerName: string;
  sellerId: ISeller["id"];
  price: number;
  unitPrice: number;
  rowTotal: number;
  shipmentId: string;
  siteId?: string; // deprecated: siteId string before OMNI-595
  siteCode?: string;
  siteIdInt?: number;
  siteName?: string;
  siteAddress?: string;
  status?: string;
  isGift?: boolean;
  clearanceTypeTag?: string;
  clearanceTypeName?: string;
  clearanceTypeDescription?: string;
  clearanceBucketDiscountPercent?: number;
  barcodes?: IBarcode[];
  uom?: string;
  displayName?: string;
  siteIdFromFfr?: ISite["id"]; // siteId integer from FFR after OMNI-595
  uomName?: string;
}

export interface RawRouterItem extends RawOrderItem {
  fulfillmentItemId?: string;
  holdItemId?: number;
  warehouse?: string;
  priority?: number;
  isSuccessfulGroupBuy?: boolean;
  promotions?: IPromotion[];
}

export interface IOrderItem extends RawRouterItem {
  source?: OrderItemSource;
}

export enum OrderItemSource {
  OC = "OC",
  FFR = "FFR",
}

export interface IOrderCustomer {
  id: string;
  iamId: string; // deprecated after OMNI-1018
  name: string;
  phone: string;
  email: string;
}

export interface IOrderCreator {
  id: string;
  email: string;
  name: string;
  phone: string;
}
// This interface uses for Apis pre 1018
export interface IShippingInfo {
  name: string;
  phone: string;
  fullAddress: string;
  expectedReceiveFrom: string;
  expectedReceiveTo: string;
  email?: string;
  scheduledDeliveryDate?: string;
  scheduledDeliveryTimeSlotFrom?: string;
  scheduledDeliveryTimeSlotTo?: string;
  expectedDate?: string;
  storeCode?: string;
}

export enum BillingTaxType {
  VAT = "01",
  TAX_REFUND_INVOICE = "02",
  TAX_FREE_INVOICE = "03",
  TAX_GIFT_INVOICE = "04",
  TAX_GIFT_PRIZE_INVOICE = "05",
  TAX_PRIZE_INVOICE = "06",
}

export interface IBillingInfo {
  email: string;
  name: string;
  address: string;
  taxCode?: string;
  passportNumber?: string;
  phone?: string;
  showBuyerName?: boolean;
  billingTaxType?: BillingTaxType;
  passportDateOfIssue?: string;
  passportDateOfExpiry?: string;
}

export interface OrderBillingInfoUpdatePayload extends Omit<IBillingInfo, "phone"> {}

export enum OrderItemStatus {
  new = "NEW",
  waitTransfer = "WAIT_FOR_TRANSFER",

  // Item Status before Paid
  waitWarehouseConfirm = "WAITING_FOR_WAREHOUSE_CONFIRMATION",

  // Item Status for Hold / Backorder
  waitBackorderConfirm = "WAITING_FOR_BACKORDER_CONFIRMATION",
  waitManualConfirm = "WAITING_FOR_MANUAL_CONFIRMATION",
  backorderInsufficientStock = "BACKORDER_INSUFFICIENT_STOCK",
  backordering = "BACKORDERING",

  // Returned Item Status
  returned = "RETURNED",
  cancelled = "CANCELLED",

  unspecified = "UNSPECIFIED",
}

export enum OrderTabKey_Pre1018 {
  all = "ALL",
  waitConfirm = "WAITING_FOR_CONFIRMATION",
  backorderInsufficientStock = "BACKORDER_INSUFFICIENT_STOCK",
  waitProviders = "WAITING_FOR_PROVIDERS",
  backordering = "BACKORDERING",
}

export enum OrderTabKey {
  waitingForConfirmation = "WAITING_FOR_CONFIRMATION",
  backorderInsufficientStock = "INSUFFICIENT_STOCK",
  waitingForProduct = "WAITING_FOR_PRODUCT",
  waitingForDispatch = "WAITING_FOR_DISPATCH",
  search = "SEARCH",
}

export type OrderTabPaneType_Pre1018 = {
  key: OrderTabKey_Pre1018;
  name: string;
  defaultFilter: {
    status?: OrderStatus[];
    subStatus?: OrderItemStatus[];
    shipmentStatus?: ShipmentStatus[];
  };
  fieldDisabled?: string[];
};

export type OrderTabPaneType = {
  key: OrderTabKey;
  name: string;
  countGroupKey?: string;
  shipmentStatus?: string[];
  trackingTab: string;
};

export type OrderTabsType = {
  [key: string]: OrderTabPaneType;
};

export type OrderTabsType_Pre1018 = {
  [key: string]: OrderTabPaneType_Pre1018;
};

export type OrderTabsCount = {
  [key: string]: number;
};

export enum ShipmentStatus {
  //before 1018
  thirdPlConfirmed = "3PL_CONFIRMED",
  //--------------------------//
  new = "NEW",
  reverted = "REVERTED",
  waitReturnConfirm = "WAITING_FOR_RETURN_CONFIRMATION",
  waitRevertConfirm = "WAITING_FOR_REVERT_CONFIRMATION",
  returned = "RETURNED",
  cancelled = "CANCELLED",
  partialCancelled = "PARTIAL_CANCELLED",
  delivering = "DELIVERING",
  delivered = "DELIVERED",
  wait3PlConfirm = "WAITING_FOR_3PL_CONFIRMATION",
  thirdPlPickedUp = "3PL_PICKEDUP",
  serviceCheck = "SERVICE_CHECK",
  packed = "PACKED",
  waitPacking = "WAITING_FOR_PACKING",
  picking = "PICKING",
  waitPicking = "WAITING_FOR_PICKING",
  waitProduct = "WAITING_FOR_PRODUCT",
  providerTransferred = "PROVIDER_TRANSFERRED",
  waitManualConfirm = "WAITING_FOR_MANUAL_CONFIRMATION",
  //After 1018
  waitBackorderConfirm = "WAITING_FOR_BACKORDER_CONFIRMATION",
  backorderInsufficientStock = "BACKORDER_INSUFFICIENT_STOCK",
  backordering = "BACKORDERING",
  waitTransfer = "WAIT_FOR_TRANSFER",
  picked = "PICKED",
  readyPickup = "READY_FOR_PICKUP",
  providerTransferring = "PROVIDER_TRANSFERRING",
  deliveredAtAgency = "DELIVERED_AT_AGENCY",
  deliveredToCustomer = "DELIVERED_TO_CUSTOMER",
}

export enum CalculatedShipmentStatus {
  deliveringToAgency = "DELIVERING_TO_AGENCY",
}

export enum OrderStatus {
  // deprecated: before OMNI-1018
  draft = "DRAFT",
  waitApproval = "WAITING_FOR_APPROVAL",
  created = "CREATED",
  waitPaymentConfirm = "WAITING_FOR_PAYMENT_CONFIRMATION",
  paymentConfirmed = "PAYMENT_CONFIRMED",
  ppmFailed = "PPM_FAILED",
  paymentFailed = "PAYMENT_FAILED",
  cancelledDraft = "CANCELLED_DRAFT",
  confirmed = "CONFIRMED",
  waitProduct = "WAITING_FOR_PRODUCT",
  waitPicking = "WAITING_FOR_PICKING",
  picking = "PICKING",
  waitInstalling = "WAITING_FOR_INSTALLING",
  waitPacking = "WAITING_FOR_PACKING",
  waitDelivery = "WAITING_FOR_DELIVERY",
  waitTransferringLo = "WAITING_FOR_TRANSFERRING_LO",
  processingAtWms = "PROCESSING-AT-WMS",
  delivering = "DELIVERING",
  waitCancellingConfirm = "WAITING_FOR_CANCELLING_CONFIRM",
  reverted = "REVERTED",
  waitReturningApproval = "WAITING_FOR_RETURNING_APPROVAL",
  waitReturningConfirm = "WAITING_FOR_RETURNING_CONFIRM",

  // after OMNI-1018
  pending = "PENDING",
  cancelledBeforeFulfill = "CANCELLED_BEFORE_FULFILL",
  unpaid = "UNPAID",
  partiallyPaid = "PARTIALLY_PAID",
  delivered = "DELIVERED",
  processing = "PROCESSING",
  needAction = "NEED_ACTION",
  cancelled = "CANCELLED",
  returned = "RETURNED",
  orderPlaced = "ORDER_PLACED",
}

export enum OrderSortingKey {
  createdAtAsc = "createdAt",
  createdAtDesc = "-createdAt",
  updatedAtAsc = "updatedAt",
  updatedAtDesc = "-updatedAt",
}

export type OrderSortingType = {
  [key: string]: OrderSortingKey;
};

export interface OrderFilter_Pre1018 {
  code?: string;
  sellerId?: ISeller["id"];
  terminalCode?: string[];
  status?: string[];
  subStatus?: string[];
  shipmentStatus?: string[];
  phone?: string;
  orderDate?: RangeValue<Dayjs>;
  page?: number;
  pageSize?: number;
  platformId?: IPlatform["id"];
  tabKey?: OrderTabKey_Pre1018;
  sort?: OrderSortingKey;
}

export interface SellerOrderFilter {
  consultantId?: string[];
  platformId?: IPlatform["id"];
  phone?: string;
  orderCreatedDate?: RangeValue<Dayjs>;
  deliveredDate?: RangeValue<Dayjs>;
  paymentDate?: RangeValue<Dayjs>;
  orderId?: string;
  shipmentStatus?: string[]; // ShipmentStatus from filter dropdown
  terminalId?: number[];
  skus?: string[];
  offset?: number;
  limit?: number;
  sort?: OrderSortingKey;
  exportSiteIds?: number[];
}

export interface OrderFilter {
  platformId: IPlatform["id"];
  sellerId?: number;
  orderStatus?: string;
  shipmentStatus?: string[]; // ShipmentStatus from filter dropdown
  consultantId?: string[];
  orderCreatedDate?: RangeValue<Dayjs>;
  deliveredDate?: RangeValue<Dayjs>;
  paymentDate?: RangeValue<Dayjs>;
  orderId?: string;
  phone?: string;
  terminalId?: number[];
  limit?: number;
  offset?: number;
  sort?: OrderSortingKey;
  isReadyToFulfill?: boolean;
  exportSiteIds?: number[];
  skus?: string[];
}

export interface OrdersExportRequestPayload {
  sellerIds?: number[];
  platformId?: number[];
  terminalCode?: string[];
  status?: string[];
  subStatus?: string[];
  shipmentStatus?: string[];
  phone?: string[];
  startDate: string;
  endDate: string;
}

export interface CreateExportRequestForOrdersByPlatformPayload {
  columnTemplateId: ColumnTemplateId;
  sellerId?: ISeller["id"];
  confirmationStatus?: string[];
  paymentStatus?: string[];
  fulfillmentStatus?: string[];
  consultantIds?: string[];
  isDelivered?: boolean;
  terminalId?: number[];
  shipmentStatus?: string[];
  phone?: string[];
  orderCompletedAtLte?: string;
  orderCompletedAtGte?: string;
  orderCreatedAtGte?: string;
  orderCreatedAtLte?: string;
  deliveredAtGte?: string;
  deliveredAtLte?: string;
  paidAtGte?: string;
  paidAtLte?: string;
  fileType?: string;
  exportSiteIds?: number[];
  skus?: string[];
}

export interface CreateExportRequestForOrdersBySellerPayload {
  columnTemplateId: ColumnTemplateId;
  platformId?: IPlatform["id"];
  terminalIds?: number[];
  shipmentStatus?: string[];
  phone?: string[];
  orderCreatedAtGte?: string;
  orderCreatedAtLte?: string;
  deliveredAtGte?: string;
  deliveredAtLte?: string;
  orderIds?: string[];
  fileType?: string;
}

export interface OrderGetListPayload extends Omit<OrderFilter, "orderCreatedDate" | "OrderStatus" | "deliveredDate"> {
  createdAtGte?: string;
  createdAtLte?: string;
  deliveredAtGte?: string;
  deliveredAtLte?: string;
  confirmationStatus?: string[];
  paymentStatus?: string[];
  fulfillmentStatus?: string[];
  isDelivered?: boolean | null;
}

export interface GetTotalFilteredOrderPayload
  extends Omit<OrderFilter | OrderGetListPayload, "offset" | "sort" | "limit"> {
  customer?: string;
}

export interface SellerOrderGetListPayload extends Omit<SellerOrderFilter, "orderCreatedDate" | "deliveredDate"> {
  createdAtGte?: string;
  createdAtLte?: string;
  deliveredAtGte?: string;
  deliveredAtLte?: string;
}

export interface OrderGetListPayload_Pre1018 extends Omit<OrderFilter_Pre1018, "page" | "pageSize" | "orderDate"> {
  startDate?: string;
  endDate?: string;
  limit?: number;
  offset?: number;
}

export type WarehousesBySeller = { [key: number]: IWarehouse[] };
export type IsLoadingWarehousesBySeller = { [key: number]: boolean };

export type OrderItemsByStatus = { [key: string]: IShipment["items"] };

export type OrderItemsBySeller = OrderItemsByStatus;

export type OrderItemsByStatusAndSeller = { [key: string]: OrderItemsByStatus };

export interface OrderItemConfirmWarehousePayload {
  sellers: {
    id: IOrderItem["sellerId"];
    siteId: ISite["id"];
    siteCode?: ISite["code"];
  }[];
}

export interface OrderItemConfirmPayload {
  orderId: MarketOrder["id"];
  holdItems: {
    warehouse: IOrderItem["warehouse"];
    holdItemId?: IOrderItem["holdItemId"]; // deprecate after epic omni-252;
    fulfillmentItemId?: IOrderItem["fulfillmentItemId"];
    quantity?: number;
  }[];
  forceCreateShipment?: boolean;
}

export interface RouterItemConfirmPayload {
  orderId: Order["orderId"];
  holdItems: {
    warehouse: WarehouseBiasedItem["warehouse"];
    holdItemId?: WarehouseBiasedItem["holdItemId"]; // deprecate after epic omni-252;
    fulfillmentItemId?: WarehouseBiasedItem["fulfillmentItemId"];
    quantity?: number;
  }[];
  forceCreateShipment?: boolean;
}

export interface OrderItemChangeWarehousePayload {
  orderId: MarketOrder["id"];
  items: {
    holdItemId?: IOrderItem["holdItemId"]; // deprecate: remove after omni-252
    fulfillmentItemId?: IOrderItem["fulfillmentItemId"];
    warehouse: IOrderItem["warehouse"];
  }[];
}

export interface RouterItemChangeWarehousePayload {
  orderId: Order["orderId"];
  items: {
    holdItemId?: WarehouseBiasedItem["holdItemId"]; // deprecate: remove after omni-252
    fulfillmentItemId?: WarehouseBiasedItem["fulfillmentItemId"];
    warehouse: WarehouseBiasedItem["warehouse"];
  }[];
}

export interface OrderPriorityPayload {
  orderId: MarketOrder["id"];
  priority: 0 | 1;
}

export interface OrderItemSwapHoldPayload {
  item: {
    quantity: IOrderItem["quantity"];
    id?: IOrderItem["holdItemId"]; // deprecate after epic omni-252
    fulfillmentItemId?: IOrderItem["fulfillmentItemId"];
  };
  releaseOrderCode: MarketOrder["code"];
}

export interface RouterItemSwapHoldPayload {
  item: {
    quantity: WarehouseBiasedItem["quantity"];
    id?: WarehouseBiasedItem["holdItemId"]; // deprecate after epic omni-252
    fulfillmentItemId?: WarehouseBiasedItem["fulfillmentItemId"];
  };
  releaseOrderCode: Order["orderId"];
}

export interface CloneOrderPayload {
  orderId: MarketOrder["id"];
  platformId: IPlatform["id"];
}

export enum UOM {
  kilogram = "kg",
  gram = "g",
  centimeter = "cm",
  allowDecimal = "allowDecimal",
}

export enum OrderDetailTabKey {
  Order = "order",
  Return = "return",
  History = "history",
}

export enum OrderCountUOM {
  Order = "order",
  Shipment = "shipment",
}

export interface CloneOrderResponse {
  cartToken: string;
  cartUrl: string;
  statusCode: number;
}

export interface IConsultant {
  id?: string;
  customId?: string;
  name?: string;
  phone?: string;
  email?: string;
}

export interface IBarcode {
  source: string;
  barcode: string;
  isDefault: boolean;
}

export interface IGroupBuyInfo {
  remain?: number;
}

export interface IPromotionDefinition {
  endedAt?: string;
  condition?: { groupBuyInfo?: IGroupBuyInfo };
}

export interface IGift {}

export interface IPromotion {
  definition?: IPromotionDefinition;
  gifts2?: IGift[];
}

// TODO: remove this after OMNI-1018
export interface AllowedUserAction {
  actionCode: ActionCode;
}

// TODO: remove this after OMNI-1018
export enum ActionCode {
  createBoL = "ACTION_CODE_SHIPMENT_CREATE_BOL",
  // below are defined actions from api-doc but not sure if it has been implemented
  editOrder = "ACTION_CODE_EDIT_ORDER_AT_STORE",
  markAsShipped = "ACTION_CODE_SHIPMENT_MARK_AS_SHIPPED",
  markAsPacked = "ACTION_CODE_SHIPMENT_MARK_AS_PACKED",
  markAsPicking = "ACTION_CODE_SHIPMENT_MARK_AS_CONFIRM_PICKING",
  markAsDelivering = "ACTION_CODE_SHIPMENT_MARK_AS_DELIVERING",
  markAsConfirmReceived = "ACTION_CODE_SHIPMENT_MARK_AS_CONFIRM_RECEIVED",
  partialReturn = "ACTION_CODE_SHIPMENT_PARTIAL_RETURN",
  orderComment = "ACTION_CODE_ORDER_COMMENT",
  updateCustomer = "ACTION_CODE_ORDER_UPDATE_CUSTOMER",
}

export interface AllowedUserActionOnOrder {
  actionCode: OrderActionCode;
}

export enum OrderActionCode {
  OrderComment = "ACTION_CODE_ORDER_COMMENT",
  UpdateCustomer = "ACTION_CODE_ORDER_UPDATE_CUSTOMER",
  Clone = "ACTION_CODE_ORDER_CLONE",
  Cancel = "ACTION_CODE_ORDER_CANCEL",
  UpdateVATInfo = "ACTION_CODE_ORDER_UPDATE_VAT_INFO",
}

export interface AllowedUserActionOnShipment {
  actionCode: ShipmentActionCode;
}

export enum ShipmentActionCode {
  MarkAsShipped = "ACTION_CODE_SHIPMENT_MARK_AS_SHIPPED",
  CreateBoL = "ACTION_CODE_SHIPMENT_CREATE_BOL",
  CancelToReprocess = "ACTION_CODE_SHIPMENT_CANCEL_TO_REPROCESS",
  MarkAsProviderDelivered = "ACTION_CODE_SHIPMENT_MARK_AS_PROVIDER_DELIVERED",
}

export type PriceSummary = {
  name: string;
  value: string;
  tags: string[];
  isHighlighted: boolean;
};

// deprecated after OMNI-1018
export type Invoice = {
  id: string;
  sellerId: number;
  sellerName: string;
  type: InvoiceType;
  invoiceItems: InvoiceItem[];
  codAmount: string; // int64 from api proto
};

export type InvoiceItem = {
  lineItemId: string;
  sku: string;
  quantity: number;
  type: InvoiceItemType;
  name: string;
  unitAfterTaxPrice: number;
  totalAfterTaxAmount: number;
  sellerId?: number;
  sellerName?: string;
};

export enum InvoiceItemType {
  service = "service",
  item = "item",
}

export enum InvoiceType {
  service = "INVOICE_TYPE_SERVICE",
  sales = "INVOICE_TYPE_SALES",
  cancelledBeforeAllocatedService = "INVOICE_TYPE_CANCELED_BEFORE_ALLOCATED_SERVICE",
}

export enum OrderType {
  market = "market",
  seller = "seller",
}

export interface Comment {
  content: string;
  staffName: string;
  commentTime: string;
}

export interface Assignee {
  staffId?: number;
  staffName?: string;
}

export interface TeamOption {
  teamId: number;
  teamName?: string;
  selected?: boolean;
}

export interface CommentsAndAssignee {
  ticketId?: number;
  comments: Comment[];
  assignee?: Assignee;
  teamOptions: TeamOption[];
}

export interface PostOrderCommentPayload {
  ticketId?: number;
  content?: string;
  teamId?: number;
  assigneeStaffId?: number;
}

export interface CommentFormValues {
  team: TeamOption["teamId"];
  assignee: Assignee["staffId"];
  content: Comment["content"];
}

export interface OrderConsultantUpdatePayload {
  consultant: {
    id?: string;
    name?: string;
    email?: string;
    phone?: string;
    customId?: string;
  };
}

export interface OrderCustomerUpdatePayload {
  customerInfo: {
    id?: string;
    profileId?: string;
    name?: string;
    email?: string;
    phone?: string;
  };
}

export interface CustomerGetListParams {
  platformId?: number;
  isActive?: number;
  sortField?: string;
  sortOrder?: string;
  keyword?: string;
}

export interface CustomerGetListItem {
  id?: string;
  userId?: string;
  name?: string;
  telephone?: string;
  email?: string;
  sex?: string;
  dob?: string;
}

export interface CustomerCreatePayload {
  platformId: number;
  name: string;
  telephone: string;
  sex?: string;
  dob?: string | dayjs.Dayjs;
  profileId?: string;
}

export enum ColumnTemplateId {
  invalid = 0,
  staff = 1,
  seller = 2,
  takaInvoices = 3,
  vieonSalesReport = 4,
  staffOrderHeader = 5,
  staffOrderDetail = 6,
  vnpostRevenueReport = 8,
  vnpostOrderDetail = 9,
  maxtrustRevenueReport = 10,
}

export enum ExportFileType {
  csv = "csv",
  excel = "excel",
}

export interface ExportOrdersCreatePayload {
  columnTemplateId: ColumnTemplateId;
  orderCompletedAtGte?: string; // min time that order is completed
  orderCompletedAtLte?: string; // max time that order is completed
}

export enum CustomerStep {
  Search,
  Add,
  Edit,
}

export interface CustomerInformation {
  name?: string;
  phone: string;
  sex?: string;
  dob?: string;
  profileId?: string;
}

export interface CountNeedToActionsGetParams {
  countGroups?: string[];
  createdAtGte?: string;
  createdAtLte?: string;
  platformId?: number;
  sellerId?: number;
}

export type NeedToActionsTotalCount = { [key in Exclude<keyof typeof OrderTabKey, "search">]: number };

export enum CountGroupKey {
  waitingConfirmation = "COUNT_GROUP_WAITING_FOR_CONFIRMATION",
  insufficientStock = "COUNT_GROUP_INSUFFICIENT_STOCK",
  waitingForProduct = "COUNT_GROUP_WAITING_FOR_PRODUCT",
  waitingForDispatch = "COUNT_GROUP_WAITING_FOR_DISPATCH",
}

export interface OrderActor {
  id: string;
  name: string;
  email: string;
  phone?: string;
}
// This interface uses for Apis after 1018
export interface ShippingInfo {
  name: string;
  phone: string;
  fullAddress: string;
  scheduledDeliveryDate: string | null;
  siteDisplayName?: string;
  scheduledDeliveryTimeSlotFrom?: string;
  scheduledDeliveryTimeSlotTo?: string;
  email?: string;
  deliveryType?: DeliveryType;
}

export type Customer = OrderActor;

export type Creator = OrderActor;

export interface Consultant {
  customId: string;
  email: string;
  name: string;
  phone: string;
}

export interface Payment {
  paymentMethod: string;
  amount: number;
  transactionCode: string;
  partnerTransactionCode: string;
  createdAt: string;
  paidAt: string;
  cashierDisplayName: string;
}

export interface OrderListPayment extends Pick<Payment, "paidAt" | "paymentMethod"> {}

export interface Order {
  orderId: string;
  createdAt: string;
  customer: Customer | null;
  shippingInfo: ShippingInfo | null;
  grandTotal: number;
  createdFromSiteDisplayName: string;
  confirmationStatus: ConfirmationStatus;
  paymentStatus: PaymentStatus;
  isHandover: boolean;
  fulfillmentStatus: FulfillmentStatus;
  hasReturnedFullItems: boolean;
  terminalName: string;
  platformId: number;
  replacedOrderId: string;
  replacedByOrderId: string;
  creator: Creator | null;
  consultant: Consultant | null;
  payments: Payment[];
  note: string;
  lastWarehouseExportAt: string | null;
  priceSummary?: PriceSummary[];
}

export interface RawOrderDetails extends Order {
  services: Service[];
  billingInfo: IBillingInfo | null;
  orderCaptureLineItems: OrderCaptureLineItem[];
  shipments: RawShipment[];
  cancellationReason: string;
  externalOrderRef: string;
  baseTotalAfterTaxAmount: number;
  totalPromotionAfterTaxAmount: number;
  totalPriceIncreaseAfterTaxAmount: number;
  totalOnDemandDiscountAfterTaxAmount: number;
  remainPaymentFloat64: number;
  allowedUserActions: AllowedUserActionOnOrder[];
  codAmount?: number; // For seller screen
}

export interface OrderDetails extends Omit<RawOrderDetails, "shipments"> {
  shipments: Shipment[];
  status: OrderDisplayStatus; // this field is not from API but is calculated by other status fields
  isOmni252EnabledForOrderPlatform?: boolean;
  isOmni1251EnabledForOrderPlatform?: boolean;
}

interface OrderItemCommonFields {
  quantity: number;
  sellerSku: string;
  sku: string;
  name: string;
  sellerName: ISeller["name"];
  sellerId: ISeller["id"];
  barcodes: IBarcode[];
  clearanceTypeTag: string;
  clearanceTypeName: string;
  clearanceTypeDescription: string;
  clearanceBucketDiscountPercent: number;
  uomName: string;
}

export interface OrderCaptureLineItem extends OrderItemCommonFields {
  sellPrice: number;
  finalSellPrice: number;
  rowTotal: number;
}

export interface RawShipment {
  shipmentId: string;
  shipmentStatus: string;
  sellerId: ISeller["id"];
  codAmount: number;
  isAuto: boolean;
  allowPartialReturn: boolean;
  allowedUserActions: AllowedUserActionOnShipment[];
  delivery: DeliveryInfo;
  items: GeneralViewItem[];
  services: Service[];
}

export interface Shipment extends Omit<RawShipment, "items" | "services"> {
  generalItems: GeneralViewItem[];
  warehouseItems: WarehouseBiasedItem[];
}

export interface GeneralViewItem extends OrderCaptureLineItem {
  shipmentId: string;
  priority: number;
  holdItemId: string; // deprecated after OMNI-252
  fulfillmentItemId: string;
  warehouse: IWarehouse["code"];
  siteId: ISite["id"];
  siteDisplayName: string;
  siteTransferId: string;
  siteTransferStatus: string;
  siteTransferExpectedIncomingDate: string;
  missingQuantity: number;
}

export interface Service {
  serviceId: number;
  sellerSku: string;
  sellPrice: number;
  finalSellPrice: number;
  name: string;
  sellerId: ISeller["id"];
  sellerName: ISeller["name"];
  identifier?: string; // only exists for service which is not allocated to any real shipments
}

export interface SellerOrder {
  orderId: string;
  createdAt: string;
  terminalName: string;
  customer: OrderActor | null;
  creator: OrderActor | null;
  consultant: Consultant | null;
  shippingInfo: ShippingInfo | null;
  grandTotal: number;
  shipmentId: string;
  shipmentStatus: string;
  sellerId: number;
  platformId: number;
  payments: OrderListPayment[];
  note?: string;
  allowedUserActions?: AllowedUserAction[];
}

export interface StatusMap {
  confirmationStatus: ConfirmationStatus;
  paymentStatus?: PaymentStatus;
  fulfillmentStatus?: FulfillmentStatus;
  isHandover?: boolean;
  hasReturnedFullItems?: boolean;
}

export interface OrderStatusMap {
  label: string;
  value: string;
  color: string;
  status: StatusMap;
  isAddedInOMNI1058?: boolean;
  isRemovedInOMNI1058?: boolean;
}

export interface OrderDisplayStatus {
  displayName: string;
  displayColor: string;
  value: string;
}

export enum ConfirmationStatus {
  pending = "pending",
  cancelled = "cancelled",
  active = "active",
}

export enum PaymentStatus {
  pending = "pending",
  partiallyPaid = "partially_paid",
  fullyPaid = "fully_paid",
}

export enum FulfillmentStatus {
  delivered = "DELIVERED",
  new = "NEW",
  processing = "PROCESSING",
  needAction = "NEED_ACTION",
  cancelled = "CANCELLED",
  returned = "RETURNED",
}

export interface StatusMappedType {
  params: {
    confirmationStatus?: string[];
    paymentStatus?: string[];
    isHandover?: boolean;
    fulfillmentStatus?: string[];
    isDelivered?: boolean;
    hasReturnedFullItems?: boolean;
  };
  name: string;
}

export interface OrderStatusFilter {
  [key: string]: StatusMappedType;
}

export interface ShipmentStatusMap {
  [key: string]: {
    name: string;
    shipmentStatus: string[]; // use for mapping from filter to search order param
    useForFilter: boolean;
    color: string | undefined; // use for mapping from shipment status to shipment status tag
  };
}

export interface SearchOrderTabForm {
  sellerId?: ISeller["id"];
  terminalId?: number[];
  phone?: string;
  consultantId?: string[];
  orderStatus?: string;
  shipmentStatus?: string[];
  orderCreatedDate?: RangeValue<Dayjs>;
  deliveredDate?: RangeValue<Dayjs>;
  paymentDate?: RangeValue<Dayjs>;
  exportSiteIds?: number[];
  skus?: string[];
}

export interface SellerOrderSearchForm extends Omit<SearchOrderTabForm, "sellerId"> {
  orderId?: string;
  platformId?: IPlatform["id"];
}

export interface OrderTableRecord {
  order: Pick<Order, "orderId" | "createdAt" | "terminalName" | "lastWarehouseExportAt">;
  contact: {
    customer: OrderActor | null;
    creator: OrderActor | null;
    consultant: Consultant | null;
  };
  payment: {
    grandTotal: Order["grandTotal"];
    paymentMethods: Payment["paymentMethod"][];
    latestPaymentDate: Payment["paidAt"];
  };
  orderStatus: {
    confirmationStatus: ConfirmationStatus;
    paymentStatus: PaymentStatus;
    fulfillmentStatus: FulfillmentStatus;
    isHandover: boolean;
    hasReturnedFullItems: boolean;
  };
  shippingInfo: ShippingInfo;
  note: Order["note"];
}

export interface SiteTransferData {
  id: string;
  status: string;
}

export interface WarehouseBiasedFinalSellPrice {
  quantity: number;
  finalSellPrice: number;
}

export interface WarehouseBiasedItem extends Omit<GeneralViewItem, "finalSellPrice"> {
  finalSellPrices: WarehouseBiasedFinalSellPrice[];
  identifier: string; // identifier which is built from `sku-warehouse-status-sellerId` of item or `id` of service
  type: ItemType;
  siteTransfers: SiteTransferData[];
  shipmentStatus?: Shipment["shipmentStatus"];
}

export enum ItemType {
  Item = "item",
  Service = "service",
}

export enum ExportHistoryType {
  market = "om_export_orders_by_platform",
  seller = "om_export_orders_by_seller",
  vnpostRevenue = "om_export_revenue_report",
  vieonSalesReport = "om_export_sales_report",
  returnRequest = "return_export_list_return_request",
  discountApprovedOrders = "approval_discount_report_order",
  inventoryAlert = "inventory_alert_report",
  warehouseSiteInfo = "warehouse_site_info",
  warrantyActivation = "export_warranty_activation_result",
  listOrderForIT = "om_list_order_for_it_team",
  listOrderForEC = "om_list_order_for_ecommerce_team",
}

export interface PriceBiasedItem
  extends Pick<
    GeneralViewItem,
    "name" | "sellerId" | "finalSellPrice" | "quantity" | "sku" | "sellerSku" | "shipmentId" | "siteId"
  > {
  type: ItemType;
  identifier: string; // `sku-finalSellPrice` for item, `serviceId` for service
}

export interface SelectedRouterItem extends WarehouseBiasedItem {
  shipmentStatus: Shipment["shipmentStatus"];
}

export enum ConfirmRouterItemsActionType {
  ManualConfirm, // confirm hold to create shipment
  BackorderConfirm, // confirm to process backorder
  ConfirmToProcess, // confirm hold to create shipment & process backorder
}

export enum DeliveryType {
  pickup = "DELIVERY_TYPE_PICKUP",
}

export enum TrialOptionCode {
  ChoThuHang = "CHOTHUHANG",
  ChoXemHangKhongThu = "CHOXEMHANGKHONGTHU",
  KhongChoXemHang = "KHONGCHOXEMHANG",
}

export interface CreateExportRequestForDiscountApprovedOrdersPayload {
  fromApprovedDiscountRequest?: number;
  toApprovedDiscountRequest?: number;
}

export interface CancelOrderPayload {
  reasonId: string;
  reason: string;
}

export interface CancelOrderResponse {
  code: number;
  message: string;
}

export interface IOrderItemChangeSite {
  fulfillmentItemId: string;
  siteId: number;
  quantity: number;
}

export interface IOrderItemChangeSitePayload {
  orderId: string;
  items: IOrderItemChangeSite[];
  isAllowCreateStockRequest?: boolean;
}

export interface IOrderItemsConfirmPayload {
  orderId: string;
  items: IOrderItemChangeSite[];
  isForceCreateShipment?: boolean;
  isAllowCreateStockRequest?: boolean;
}

export interface IOrderItemsConfirmResponse {
  shipments: {
    shipmentId: string;
  }[];
}

export enum SITE_TRANSFER_STATUS {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
  CANCELLED = "CANCELLED",
  EXPORTING = "EXPORTING",
  DELIVERING = "DELIVERING",
  CLOSED_PARTIALLY_IMPORTED = "CLOSED_PARTIALLY_IMPORTED",
  PROVIDER_TRANSFERRED = "PROVIDER_TRANSFERRED",
  RETURNED = "RETURNED",
  RETURNING = "RETURNING",
}

export interface IGetUserTerminalsParams {
  platformId?: IPlatform["id"];
  sellerId?: ISeller["id"];
}

export enum SAMSUNG_WARRANTY_ACTIVATION_STATUS {
  success = "success",
  fail = "fail",
  pending = "pending",
}

export interface ISamsungWarrantyActivationForm extends Pick<ISamsungWarrantyActivationPayload, "statuses"> {
  recordedDate: RangeValue<Dayjs>;
}

export interface ISamsungWarrantyActivationPayload {
  fromDate: number;
  toDate: number;
  statuses?: SAMSUNG_WARRANTY_ACTIVATION_STATUS[];
}

export enum ORDER_EXPORT_TYPE_FOR_CUSTOMER_TEAM {
  LIST_ORDER_FOR_IT_TEAM = "LIST_ORDER_FOR_IT_TEAM",
  LIST_ORDER_FOR_EC_TEAM = "LIST_ORDER_FOR_EC_TEAM",
}

export interface IExportListOrderForCustomerTeamPayload {
  exportType: ORDER_EXPORT_TYPE_FOR_CUSTOMER_TEAM;
  sellerId?: ISeller["id"];
  confirmationStatuses?: string[];
  paymentStatuses?: string[];
  fulfillmentStatuses?: string[];
  consultantIds?: string[];
  isDelivered?: boolean;
  terminalIds?: number[];
  shipmentStatuses?: string[];
  customerPhone?: string[];
  orderCreatedAtGte?: number;
  orderCreatedAtLte?: number;
  deliveredAtGte?: number;
  deliveredAtLte?: number;
  paidAtGte?: number;
  paidAtLte?: number;
  exportSiteIds?: number[];
  skus?: string[];
  hasReturnedFullItems?: boolean;
}
